import React, {useEffect} from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import '../assets/style/sidebar.css';
import logo from './../assets/images/logo-removebg.png';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MapIcon from '@mui/icons-material/Map';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import RememberMeIcon from '@mui/icons-material/RememberMe';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import PostAddIcon from '@mui/icons-material/PostAdd';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';

const Sidebar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    let userData = JSON.parse(window.localStorage.getItem("userData"));
    let userRole = userData.role;

    return (
        <div className='main'>
            <div className="li">
                <li className='logoli'><img src={logo} alt='logo' className='logo' /></li>
                <li><button onClick={() => navigate("/dashboard")} className={location.pathname === '/dashboard' ? 'active' : ''}><div><DashboardIcon /><div>Dashboard</div></div><ArrowForwardIosIcon style={{ fontSize: 15 }} /></button></li>
                <li><button onClick={() => navigate("/ward")} className={location.pathname === '/ward' ? 'active' : ''}><div><MapIcon /><div>Ward</div></div><ArrowForwardIosIcon style={{ fontSize: 15 }} /></button></li>
                { userRole !== 'admin' &&
                    <li><button onClick={() => navigate("/admin")} className={location.pathname === '/admin' && 'active'}><div><AdminPanelSettingsIcon /><div>Admin</div></div><ArrowForwardIosIcon style={{ fontSize: 15 }} /></button></li>
                }
                <li><button onClick={() => navigate("/appUser")} className={location.pathname === '/appUser' && 'active'}><div><RememberMeIcon /><div>App User</div></div><ArrowForwardIosIcon style={{ fontSize: 15 }} /></button></li>
                <li><button onClick={() => navigate("/agencies")} className={location.pathname === '/agencies' && 'active'}><div><ContactPageIcon /><div>Register Agency</div></div><ArrowForwardIosIcon style={{ fontSize: 15 }} /></button></li>
                <li><button onClick={() => navigate("/registerads")} className={location.pathname === '/registerads' && 'active'}><div><AppRegistrationIcon/><div>Register Ads</div></div><ArrowForwardIosIcon style={{ fontSize: 15 }} /></button></li>
                <li><button onClick={() => navigate("/adsList")} className={location.pathname === '/adsList' && 'active'}><div><PostAddIcon/><div>Advertise List</div></div><ArrowForwardIosIcon style={{ fontSize: 15 }} /></button></li>
                <li><button onClick={() => navigate("/exisingAdList")} className={location.pathname === '/exisingAdList' && 'active'}><div><PostAddIcon/><div> Existing Advertise List</div></div><ArrowForwardIosIcon style={{ fontSize: 15 }} /></button></li>
            </div>
        </div>
    )
}

export default Sidebar;