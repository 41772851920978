import React, { useState } from 'react';
import { useEffect } from 'react';
import Header from "./../components/header";
import './../assets/style/dashboard.css';
import Loader from '../components/loader';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Grid } from "@mui/material";
import Sidebar from "./../components/Sidebar";


import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { getAllReports, getAllReportsExport } from '../api/services';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button'
 interface Column {
        id: 'agent_name' | 'email' | 'phone' | 'cost' | 'registration_expired' | 'status';
        label: string;
        minWidth?: number;
        align?: 'right';
        format?: (value: number) => string; 
    }
    const columns: Column[] = [
        { id: 'agent_name', label: 'Agent Name', minWidth: 170 },
        { id: 'email', label: 'Email', minWidth: 100 },
        { id: 'phone', label: 'Phone No', minWidth: 100 },
        { id: 'cost', label: 'Cost', minWidth: 100 },
        { id: 'registration_expired', label: 'Registration Expired', minWidth: 100 },
        { id: 'status', label: 'Status', minWidth: 100 },
        
    ];

    interface Data {
        agent_name: string;
        email: string;
        phone: string;
        cost: string;
        registration_expired: string;
        status: string;
    }

    function createData(
        agent_name: string,
        email: string,
        phone: string,
        cost: string,
        registration_expired: string,
        status: string,
        ): Data {
        return { agent_name, email, phone, cost, registration_expired, status };
    }

const Dashboard = () => {
    const [timeline, setTimeline] = React.useState('3');
    const  [rows, setRows]  = React.useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const changeTimeline = (event: SelectChangeEvent) => {
        setTimeline(event.target.value);
        reportInit(event.target.value);
    };

    useEffect(() => {
        if (window.localStorage.getItem("login")) {
            reportInit(timeline);
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        }
        else{
            navigate("/")
        }

    }, [])

    const reportInit = (timelineData) => {
        setRows([]);
        getAllReports(timelineData).then(
            response => {
                if (response.success) {                    
                    response.data.advertisement.forEach(element => { 
                        const item = createData(element['business_name'], element['email'], element['phone'], element['cost'], element['end_date'], element['status_str']);
                        console.log(item);
                        let rowsTemp = rows;
                        rowsTemp.push(item);
                        setRows(rowsTemp);
                    });
                }
                else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            }
        )
    };
    const reportsExport = () => {
        getAllReportsExport(timeline).then(
            response => {
                if (response.success) {
                    const link = document.createElement('a');
                    link.href = response.data.pdf;
                    link.target = "_blank";
                    link.rel = "noopener noreferrer";
                    link.download = "download";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
                else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            }
        )
    };

    return (
        <>
        <Header />
        <Grid container sx={{ flexGrow: 1 }}>
            <Grid item xs={0} md={2.3} xl={1.7}>
                <Sidebar />
            </Grid>
            <Grid item xs={12} md={9.7} xl={10.3}>
                <div className='dashboard'>
                    <ToastContainer />
                    {isLoading ? (
                            <div> <Loader /> </div>
                        ) : (
                        <div className='in'>
                            <div className='wardHead'>
                                <p>Report</p>
                                <Stack spacing={2} direction="row">
                                    <Button variant="contained" href='javascript:void(0);' onClick={reportsExport}>Export</Button>
                                     <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth>
                                            <Select
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            value={timeline}
                                            onChange={changeTimeline}
                                            >
                                            <MenuItem value={"1"}>Today</MenuItem>
                                            <MenuItem value={"2"}>This Week</MenuItem>
                                            <MenuItem value={"3"}>This Month</MenuItem>
                                            <MenuItem value={"4"}>This Year</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Stack>

                               
                            </div>
                            <Paper sx={{ width: '100%' }}>
                                <TableContainer sx={{ maxHeight: 440 }}>
                                    <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                            >
                                            {column.label}
                                            </TableCell>
                                        ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row) => {
                                            return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align} style={{fontSize: "14px"}}>
                                                    {value}
                                                    </TableCell>
                                                );
                                                })}
                                            </TableRow>
                                            );
                                        })}
                                       {rows.length == 0?
                                         (
                                            <TableRow>
                                               <TableCell colSpan={7} >
                                                    <center>No records found</center>
                                                </TableCell>
                                            </TableRow>
                                            ):<></>} 
                                    </TableBody>
                                    </Table>
                                </TableContainer>                               
                                </Paper>
                        </div>
                        )
                    }
                </div>
            </Grid>
        </Grid>        
      </>        
    )
}

export default Dashboard;