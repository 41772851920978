import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Login from "./screen/login";
import Dashboard from "./screen/dashboard";
import Ward from "./screen/ward";
import Admin from "./screen/admin";
import Agencies from "./screen/agencies";
import AgenciesDetails from "./screen/agenciesDetails";
import AddsAdvertisement from "./screen/addsAdvertisement";
import AppUser from "./screen/appUsers";
import AdsList from "./screen/adsList";
import AdsRegister from "./screen/registerads";
import ExisingAdList from "./screen/exisingAdList";

function App() {
  return (
      <div>
        <Router>         
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/ward" element={<Ward />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/agencies" element={<Agencies />} />
              <Route path="/agenciesDetails" element={<AgenciesDetails />} />
              <Route path="/addsAdvertisement" element={<AddsAdvertisement />} />
              <Route path="/appUser" element={<AppUser />} />
              <Route path="/adsList" element={<AdsList />} />
              <Route path="/exisingAdList" element={<ExisingAdList />} />
              <Route path="/registerads" element={<AdsRegister />} />
              {/* <Route path="/adds" element={<Adds />} />
        <Route path="/addsDetails" element={<AddsDetails />} /> */}
            </Routes>
        </Router>
      </div>
  );
}

export default App;
